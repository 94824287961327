<template>
  <div>
    <div v-if="filterItem.type === 'select'">
      <div
        class="px-2 lg:px-0"
        :class="radioOptions.length ? 'pb-2' : ''"
      >
        <div
          class="flex items-center justify-center"
          :class="radioOptions.length ? 'pb-1' : ''"
        >
          <button
            v-if="!noOptions"
            class="-mt-1.5 mr-1.5 size-6 flex-col items-center justify-center rounded-full text-trublue hover:bg-trublue hover:text-white print:hidden"
            @click="setValueGivenIndex(selectedItemIndex - 1)"
          >
            <UIcon
              name="i-mdi-chevron-double-left"
              class="mt-1 !size-4"
            />
          </button>

          <div class="max-w-[60vw] grow md:max-w-sm">
            <HubSelect
              :id="filterItem.filterName"
              :model-value="optionsLoading ? undefined : internalValue"
              :options="options"
              :placeholder="optionsLoading ? $t('loadingData') : (filterItem.showPlaceholder ? $t('filters.placeholders.' + filterItem.filterName) : undefined)"
              :by="key"
              :option-attribute="isEndpoint ? endpointTypedProps.labelKey : 'label'"
              :container-ui="isEndpoint ? 'z-40 min-w-72 lg:min-w-96' : 'z-40 min-w-36 lg:min-w-96'"
              :searchable="isEndpoint"
              :loading="optionsLoading"
              :disabled="noOptions"
              no-focus
              @update:model-value="internalValue = $event"
            />
          </div>
          <button
            v-if="!noOptions"
            class="-mt-1.5 ml-1.5 size-6 flex-col items-center justify-center rounded-full text-trublue hover:bg-trublue hover:text-white print:hidden"
            @click="setValueGivenIndex(selectedItemIndex + 1)"
          >
            <UIcon
              name="i-mdi-chevron-double-right"
              class="mt-1 !size-4"
            />
          </button>
        </div>
        <div
          v-if="isEndpoint && endpointTypedProps.groupedItemSelectable && radioOptions.length"
          class="flex w-full items-center justify-center"
        >
          <!-- radio button choices below using Nuxt UI -->
          <div
            class="grid max-w-[60vw] grow grid-cols-1 items-center justify-center gap-2 sm:grid-cols-2 md:max-w-sm"
            :class="{
              'md:grid-cols-3': radioOptions.length >= 3,
              'xl:grid-cols-4': radioOptions.length >= 4,
            }"
          >
            <URadio
              v-for="radio in radioOptions"
              :key="radio.value"
              v-bind="radio"
              color="trublue"
              :model-value="endpointTypedProps.groupedItemValue"
              @update:model-value="emit('update:groupedItemValue', $event)"
            />
          </div>
        </div>
      </div>
    </div>

    <HubInput
      v-else-if="filterItem.type === 'textfield'"
      :id="filterItem.filterName"
      v-model="internalValue"
      :label="filterItem.label"
    />
    <span v-else-if="filterItem.type === 'date'">
      <HubCalendarInput
        :id="filterItem.filterName"
        v-model="internalValue"
        :label="filterItem.label"
      />
    </span>
  </div>
</template>

<script setup lang="ts">
import groupBy from 'lodash/groupBy'
import isObject from 'lodash/isObject'
import type { ServerColumnRow, ServerDataRow, ServerDataResponse } from '~/types/component'
import type { EndpointFilterBarItem, FilterBarItem, RawDataFilterBarItem } from '~/types/configuration'
import { formatQueryParamsForAPI } from '~/utils/api-helpers'

const { t } = useI18n()

// store
const filterStore = useFilterStore()
const organisationStore = useOrganisationStore()

const { params } = storeToRefs(filterStore)
const { currentOrganisation } = storeToRefs(organisationStore)

// props
const props = defineProps<{
  filterItem: FilterBarItem
  dashboardId?: string
  parentFilters?: { [name: string]: string | number | undefined }
}>()

// emit
const emit = defineEmits<{
  (e: 'update:modelValue' | 'update:groupedItemValue', arg1: unknown): void
}>()

// local refs
const serverResponse = ref()
const internalValue: Ref<unknown> = ref(props.filterItem.value || props.filterItem.defaultValue)
const optionsLoading: Ref<boolean> = ref(true)
const options: Ref<Array<unknown>> = ref([])
const groupedData: Ref<{ [groupId: string]: Array<{ [name: string]: string | number }> }> = ref({})

// computed refs
// -- funky typing here so that we don't get a type error when we try to access the values
const isRawData: ComputedRef<boolean> = computed(() =>
  Object.prototype.hasOwnProperty.call(props.filterItem, 'rawData')
)
const isEndpoint: ComputedRef<boolean> = computed(() =>
  Object.prototype.hasOwnProperty.call(props.filterItem, 'endpoint')
)
const rawTypedProps: ComputedRef<RawDataFilterBarItem> = computed(() => props.filterItem as RawDataFilterBarItem)
const endpointTypedProps: ComputedRef<EndpointFilterBarItem> = computed(() => props.filterItem as EndpointFilterBarItem)
// -- other computed refs
const computedParamOptions: ComputedRef<{ [name: string]: string | number | unknown | undefined }> = computed(() => ({
  currentOrganisation: currentOrganisation.value,
  params: params.value,
  props
}))
const key: ComputedRef<string> = computed(() => (isEndpoint.value ? endpointTypedProps.value.valueKey : 'value'))
const noOptions: ComputedRef<boolean> = computed(() => !!(!optionsLoading.value && options.value.length <= 1))
const selectedItemIndex = computed(() => {
  return options.value.findIndex(o =>
    isObject(internalValue.value)
      ? (o as { [key: string]: string })[key.value] === (internalValue.value as { [key: string]: string })[key.value]
      : (o as { [key: string]: string })[key.value] === internalValue.value
  )
})
const radioOptions = computed(() => {
  if (
    !isEndpoint.value
    || !endpointTypedProps.value.groupedBy
    || !endpointTypedProps.value.groupedItemKey
    || !endpointTypedProps.value.groupedItemLabel
    || !groupedData.value
  ) {
    return []
  }

  let groupKey: string = internalValue.value as string
  if (isObject(internalValue.value)) {
    groupKey = (internalValue.value as { [key: string]: string })[endpointTypedProps.value.groupedBy]
  }

  const relevantGroup: Array<{ [name: string]: string | number }> = groupedData.value[groupKey]

  if (relevantGroup && relevantGroup.length > 1) {
    return relevantGroup.map((item: { [name: string]: string | number }) => {
      return {
        value: item[endpointTypedProps.value.groupedItemKey as string],
        label: item[endpointTypedProps.value.groupedItemLabel as string]
      }
    })
  }

  return []
})

// watchers
watch(
  [
    () => props.filterItem,
    () => props.dashboardId
  ],
  () => {
    if (props.filterItem.type === 'select') {
      fetchOptions()
    }

    setValue()
  },
  {
    deep: false, // setting this to true causes recursion and a browser hang when deep linking.
    immediate: true
  }
)

watch(
  [
    () => props.parentFilters,
    () => params.value
  ],
  () => {
    if (endpointTypedProps.value.shouldUseParentParams) {
      fetchOptions()
      setValue()
    }
  },
  {
    deep: true
  }
)

watch(
  () => serverResponse.value,
  () => {
    if (!serverResponse.value) {
      return
    }

    options.value = processEndpointData(serverResponse.value)
    setValue()
  },
  {
    deep: true,
    immediate: true
  }
)

watch(
  () => internalValue.value,
  val => {
    if (isObject(val)) {
      if (isRawData.value) {
        emit('update:modelValue', (val as { value: unknown }).value)
        return
      }

      if (isEndpoint.value) {
        // @ts-expect-error we know that this object will have the value property
        emit('update:modelValue', val[endpointTypedProps.value.valueKey])
        return
      }
    }

    emit('update:modelValue', val)
  },
  {
    immediate: true
  }
)

watch(
  () => radioOptions.value,
  val => {
    if (!val || !val.length) {
      emit('update:groupedItemValue', undefined)
      return
    }

    if (
      !endpointTypedProps.value.groupedItemValue
      || !val.find(o => o.value === endpointTypedProps.value.groupedItemValue)
    ) {
      emit('update:groupedItemValue', val[0].value)
    }
  }
)

// functions
async function fetchOptions() {
  optionsLoading.value = true
  options.value = []

  if (isEndpoint.value) {
    try {
      // work out query params
      let queryParams = {}
      if (endpointTypedProps.value.shouldUseParentParams) {
        queryParams = formatQueryParamsForAPI({ ...props.parentFilters, ...params.value })
      }

      // fetch data from the endpoint
      let endpoint = endpointTypedProps.value.endpoint

      if (endpointTypedProps.value.reportDefinition) {
        // if it's a report defintion then it's simple
        endpoint = `api/v4/dashboards/${props.dashboardId}/reports/${endpointTypedProps.value.endpoint}`
      } else {
        // get all params within curly braces
        const params = endpoint.match(/\{([^}]+)\}/)?.map(x => x.replace(/[{}]/g, ''))
        // if there are params then replace them with the values
        if (params) {
          // remove duplicates
          const uniqueParams = [...new Set(params)]
          for (const param of uniqueParams) {
            // split the param by the dot because nested objects are passed as a string and doesn't work out the box
            const splitParam = param.split('.')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let value = computedParamOptions.value[splitParam[0]] as any
            for (let i = 1; i < splitParam.length; i++) {
              value = value[splitParam[i]]
            }

            // replace the param with the value
            endpoint = endpoint.replace(`{${param}}`, value)
          }
        }
      }

      serverResponse.value = await $hubFetch(endpoint, { query: queryParams })
    } catch {
      serverResponse.value = undefined
    }
  }

  if (isRawData.value) {
    options.value = rawTypedProps.value.rawData.map(data => {
      return {
        value: data.value,
        label: t(data.label)
      }
    })

    internalValue.value = options.value.find(o => (o as { [key: string]: unknown }).value === internalValue.value)
  }

  optionsLoading.value = false
}

function processEndpointData(data: ServerDataResponse | Array<{ [name: string]: string | number }>) {
  let options = data as Array<{ [name: string]: unknown }>

  if (endpointTypedProps.value.reportDefinition) {
    const typedData = data as ServerDataResponse

    const columns: Array<ServerColumnRow> = typedData.results.columnHeadings
    const rows: Array<Array<ServerDataRow>> = typedData.results.rows

    const flattenedData = flattenData(columns, rows)
    options = flattenedData

    if (endpointTypedProps.value.groupedBy) {
      options = []

      const groupedFlattenedData = groupBy(flattenedData, endpointTypedProps.value.groupedBy) as {
        [index: string]: Array<{ [name: string]: string | number }>
      }

      for (const value of Object.values(groupedFlattenedData)) {
        options.push(value[0])
      }
      groupedData.value = groupedFlattenedData
    }
  }

  return options
}

function setValue() {
  if (!options.value.length || !key.value) {
    internalValue.value = props.filterItem.value || props.filterItem.defaultValue
  } else if (props.filterItem.value) {
    internalValue.value = options.value.find(o => (o as { [key: string]: unknown })[key.value] === props.filterItem.value)
  } else if (props.filterItem.defaultValue) {
    internalValue.value = options.value.find(o => (o as { [key: string]: unknown })[key.value] === props.filterItem.defaultValue)
  }

  if (!internalValue.value) {
    internalValue.value = options.value[0]
  }
}

function setValueGivenIndex(index: number) {
  if (index === -1) {
    internalValue.value = options.value[options.value.length - 1]
    return
  }

  if (index === options.value.length) {
    internalValue.value = options.value[0]
    return
  }

  internalValue.value = options.value[index]
}
</script>
